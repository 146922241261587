import Splide from "@splidejs/splide";

let carousel = document.querySelector('.js-diet-carousel');
if (carousel !== null) {
    new Splide(carousel, {
        type: 'loop',
        arrows: true,
        pagination: false,
        autoplay: false,
        // autoWidth: true,
        perMove: 1,
        perPage: 5,
        gap: '60px',
        breakpoints: {
            1500: {
                perPage: 4,
                gap: '40px'
            },
            1150: {
                perPage: 3,
                gap: '40px'
            },
        }
    }).mount();
}